<!-- 订单卡片 -->
<template>
  <div class="Card">
    <div class="top" @click="nextPage">
      <img class="img" :src="source.whiteImg" alt="" />
      <div class="main">
        <div class="title van-multi-ellipsis--l2">{{ carname }}</div>
        <div class="intro">厂商指导价：{{ source.referPrice }} 万</div>
        <div class="intro">分期方案：{{ source.planName }}</div>
      </div>
    </div>
    <div class="bottom">
      <div class="time">{{ source.time }}</div>
      <div class="status">{{ source.status }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Card",
  props: {
    source: { type: Object }
  },
  data() {
    return {};
  },
  computed: {
    carname() {
      return `${this.source.masterBrandName} ${this.source.serialName} ${this.source.year} ${this.source.styleName}`;
    }
  },
  methods: {
    nextPage() {
      this.$router.push({
        name: "OrderDetail",
        query: { id: this.source.clueId }
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.Card {
  padding: 0 0.18rem;
  position: relative;
  margin-bottom: 0.1rem;
  &::after {
    content: "";
    height: 0.1rem;
    background: #f8f8f8;
    position: absolute;
    bottom: -0.1rem;
    left: 0;
    right: 0;
  }
  .top {
    display: flex;
    padding: 0.16rem 0;
    border-bottom: 1px solid #f1f2f6;
    .img {
      width: 0.96rem;
      height: 0.64rem;
      margin-right: 0.12rem;
      flex-shrink: 0;
    }
    .main {
      flex: 1;
      .title {
        font-size: 0.15rem;
        line-height: 0.21rem;
        font-weight: bold;
      }
      .intro {
        font-size: 0.13rem;
        line-height: 0.18rem;
        color: #6e727a;
        margin-top: 0.04rem;
      }
    }
  }
  .bottom {
    padding: 0.12rem 0;
    display: flex;
    justify-content: space-between;
    font-size: 0.13rem;
    .status {
      color: #3a3d46;
    }
    .time {
      color: #8d8d91;
    }
  }
}
</style>
