<!-- 我的订单列表 -->
<template>
  <div class="MyOrder">
    <van-list
      v-model="loading"
      :finished="finished"
      :finished-text="isNoData ? '' : '到底了'"
      @load="getList"
    >
      <template v-for="item in listData">
        <Card :source="item" :key="item.clueId" />
      </template>
      <div class="noData" v-show="isNoData">
        <img src="@/assets/images/nodata.png" alt="" />
        <p>暂无内容</p>
      </div>
    </van-list>
  </div>
</template>

<script>
import Card from "./components/Card.vue";
import { getOrderList } from "@/api/api_personal";
import { mapState } from "vuex";
export default {
  name: "MyOrder",
  data() {
    return {
      isRequest: false,
      loading: false,
      finished: false,
      listData: [],
      pageIndex: 1,
      pageSize: 10
    };
  },
  computed: {
    ...mapState(["loginInfo"]),
    // 是否暂无数据
    isNoData() {
      return this.listData.length === 0 && this.isRequest;
    }
  },
  methods: {
    getList() {
      getOrderList(
        {
          pageCount: this.pageIndex,
          rowCount: this.pageSize
        },
        { headers: { token: this.loginInfo.token } }
      ).then(res => {
        if (res.code === 0) {
          if (this.pageIndex === 1) {
            this.listData = res.data.info;
          } else {
            this.listData = this.listData.concat(res.data.info);
          }
          this.pageIndex++;
          this.loading = false;
          // 数据全部加载完成
          const total = res.data.pageInfo.resultCount;
          if (this.listData.length >= total) {
            this.finished = true;
          }
        } else {
          this.finished = true;
        }
        this.isRequest = true;
      });
    }
  },
  components: { Card }
};
</script>
<style lang="scss" scoped>
.MyOrder {
  background: #fff;
  height: 100%;
  .noData {
    position: fixed;
    z-index: 8888;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    img {
      width: 1.3rem;
      height: 1.13rem;
    }
    p {
      color: #3a3d46;
      line-height: 0.22rem;
      margin-top: 0.16rem;
      text-align: center;
    }
  }
}
</style>
